"use client";
import { dismissMessageAtom, messagesAtom } from "@/store/messages";
import { useAtom } from "jotai";
import { Clear } from "@mui/icons-material";
import { classes } from "@/utils/lang";

export const MessagesComponent = () => {
  const [messages] = useAtom(messagesAtom);
  const [, dismissMessage] = useAtom(dismissMessageAtom);

  const visibleMessages = messages.filter(
    (message) => message.status === "VISIBLE"
  );

  return (
    <div
      className={classes({
        "left-0 bottom-0 w-screen": true,
        "z-50 fixed": visibleMessages.length > 0,
        hidden: visibleMessages.length === 0,
      })}
    >
      <div className="flex items-center justify-center w-full space-x-4 lg:mb-8 mb-24 font-sans">
        {visibleMessages.map((message) => (
          <div
            key={message.id}
            onClick={() => dismissMessage(message)}
            style={{ zIndex: 50 }}
          >
            {(() => {
              switch (message.type) {
                case "SUCCESS":
                  return (
                    <div className="bg-green-500 text-white px-4 py-2 rounded shadow-lg flex space-x-4">
                      <div>{message.text}</div>
                      <div>
                        <Clear />
                      </div>
                    </div>
                  );
                case "ERROR":
                  return (
                    <div className="bg-red-500 text-white px-4 py-2 rounded shadow-lg flex space-x-4">
                      <div>{message.text}</div>
                      <div>
                        <Clear />
                      </div>
                    </div>
                  );
                default:
                  return (
                    <div className="bg-blue-500 text-white px-4 py-2 rounded shadow-lg flex space-x-4">
                      {message.text}
                      <div>
                        <Clear />
                      </div>
                    </div>
                  );
              }
            })()}
          </div>
        ))}
      </div>
    </div>
  );
};
