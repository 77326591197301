import { atom, useAtom } from "jotai";

export const messagesAtom = atom<Message[]>([]);

export const dismissMessageAtom = atom(null, (get, set, message: Message) => {
  set(messagesAtom, [
    ...get(messagesAtom).map((x) => {
      if (x.id === message.id) {
        return { ...x, status: "DISMISSED" };
      } else {
        return x;
      }
    }),
  ]);
});

export const addMessageAtom = atom(null, (get, set, message: NewMessage) => {
  const messageId = new Date().getTime().toString();
  const newMessage = { ...message, id: messageId, status: "VISIBLE" };
  setTimeout(() => {
    set(dismissMessageAtom, newMessage);
  }, 5000);
  set(messagesAtom, [newMessage, ...get(messagesAtom)]);
});

export const useAddMessage = () => {
  const [, addMessage] = useAtom(addMessageAtom);

  return addMessage;
};
