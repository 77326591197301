export const SvgLogo = ({ color = "#281c34", svgClasses = "" }) => {
  return (
    <svg
      version="1.1"
      viewBox="0 0 1198.2 1141.6"
      xmlns="http://www.w3.org/2000/svg"
      className={svgClasses}
    >
      <path
        style={{ fill: "none" }}
        d="M499.58,426.67c-47.92,1.09-91.63,2.01-127.79,2.65-.1,4,.66,8.38,2.62,13.16,41.16,.69,89.94,1.68,142.58,2.85-4.38-6.64-10.57-12.94-17.41-18.67Z"
      />
      <path
        style={{ fill: "none" }}
        d="M666.28,716.27c53.78,1.35,108.11,2.75,159.48,4.09,2.85-7.78,5.05-17.72,5.05-29.91,0-1.52-.09-3.02-.25-4.51-60.19,1.57-124.45,3.2-187.49,4.73,5.83,8.78,13.4,17.63,23.21,25.6Z"
      />
      <path
        style={{ fill: "none" }}
        d="M675.57,449.06c66.63,1.64,133.59,3.36,194.75,4.98-3.77-10.66-10.82-24.37-23.86-36.01-55.01,1.45-114.09,2.98-172.72,4.45-1.06,9.6-.32,18.42,1.83,26.58Z"
      />
      <path
        style={{ fill: "none" }}
        d="M438.57,660.86c55.31,1.15,119.13,2.6,185.12,4.19v-25.99s.89,11.1,6.46,26.15c65.26,1.57,132.43,3.25,195.43,4.86-6.93-13.3-20.34-25.4-37.65-37.32-119.57,3.04-248.82,6.14-350.68,8.13,.27,7.16,.72,13.81,1.31,19.99Z"
      />
      <path
        style={{ fill: "none" }}
        d="M529.73,445.62c27.52,.62,56,1.28,84.9,1.97,2.05-9.69,5.53-17.54,9.87-23.88-25.87,.64-51.4,1.25-76.18,1.84-7.38,6.06-14.05,12.84-18.6,20.08Z"
      />
      <path
        style={{ fill: "none" }}
        d="m612.77 474.15c-79.97 1.91-156.21 3.62-217.3 4.79 3.17 5.84 6.34 12.03 9.44 18.52 61.33 1.12 135.66 2.74 213.14 4.57-2.92-8.5-4.76-17.75-5.28-27.87z"
      />
      <path
        style={{ fill: "none" }}
        d="M698.77,581.31c-94.18,2.26-188.12,4.4-264.9,5.93,.99,6.26,1.77,12.6,2.3,19,92.31,1.88,209.3,4.65,321.09,7.42-7.38-4.27-15.07-8.57-22.96-12.97-11.37-6.33-23.5-12.64-35.52-19.38Z"
      />
      <path
        style={{ fill: "none" }}
        d="m631.89 528.32c-76.75 1.82-150.71 3.49-212.12 4.7 2.24 6.28 4.34 12.73 6.25 19.32 68.88 1.3 151.28 3.13 235.36 5.13-11.25-8.54-21.4-18.08-29.49-29.15z"
      />
      <path
        style={{ fill: "none" }}
        d="m444.31 695.15c1.56 6.12 3.27 11.42 5.05 16.01 52.42 1.12 112.38 2.53 174.34 4.05v-24.08c-63.4 1.52-125.01 2.91-179.38 4.01z"
      />
      <path
        style={{ fill: color }}
        d="m515.33 69.96c30.21 0 195.95-19.56 195.95-19.56s-159.76-19.56-195.95-19.56-48.99 8.76-48.99 19.56 18.78 19.56 48.99 19.56z"
      />
      <path
        style={{ fill: color }}
        d="m390.42 172.36c81.49 0 528.53-19.56 528.53-19.56s-430.93-19.56-528.53-19.56-132.16 8.76-132.16 19.56 50.66 19.56 132.16 19.56z"
      />
      <path
        style={{ fill: color }}
        d="m354.77 223.56c91.8 0 595.37-19.56 595.37-19.56s-485.42-19.56-595.37-19.56-148.87 8.76-148.87 19.56 57.07 19.56 148.87 19.56z"
      />
      <path
        style={{ fill: color }}
        d="m332.87 275.75c105.22 0 682.44-19.56 682.44-19.56s-556.42-19.56-682.44-19.56-170.64 8.76-170.64 19.56 65.42 19.56 170.64 19.56z"
      />
      <path
        style={{ fill: color }}
        d="m311.29 327.95c111.68 0 724.33-19.56 724.33-19.56s-590.57-19.56-724.33-19.56-181.11 8.76-181.11 19.56 69.43 19.56 181.11 19.56z"
      />
      <path
        style={{ fill: color }}
        d="m298.04 380.15c119.32 0 773.91-19.56 773.91-19.56s-630.99-19.56-773.91-19.56-193.51 8.76-193.51 19.56 74.19 19.56 193.51 19.56z"
      />
      <path
        style={{ fill: color }}
        d="m846.47 418.03c-6.56-5.86-14.65-11.19-24.6-15.35-45.92-1.23-94.17-2.49-142.31-3.71-3.12 8.33-5 16.15-5.81 23.51 58.63-1.47 117.71-3 172.72-4.45z"
      />
      <path
        style={{ fill: color }}
        d="M624.5,423.7c10.97-16.06,27.36-22.53,39.54-25.13-22.93-.58-45.77-1.14-68.26-1.68-.9,.39-26.91,11.76-47.46,28.65,24.78-.58,50.3-1.2,76.18-1.84Z"
      />
      <path
        style={{ fill: color }}
        d="m1113.6 410.78s-103.8-3.02-239.06-6.69v13.19c135.02-3.58 239.06-6.5 239.06-6.5z"
      />
      <path
        style={{ fill: color }}
        d="M399.8,396.88s-27.44,10.24-28.01,32.44c36.15-.65,79.87-1.57,127.79-2.65-21.02-17.59-48.22-29.79-48.22-29.79h143.8c-131.95-3.19-251.76-5.66-305.87-5.66-152.23,0-206.12,8.76-206.12,19.56s72.32,18.8,189.87,19.51c-29.29-24.86-53.07-33.42-53.07-33.42h179.83Z"
      />
      <path
        style={{ fill: color }}
        d="m285.31 441.42h-6.06c-156.26 0-211.57 8.76-211.57 19.56s81.11 19.56 211.57 19.56c9.89 0 23.15-0.11 39.19-0.32-10.87-15.41-22.19-28.22-33.13-38.8z"
      />
      <path
        style={{ fill: color }}
        d="M675.57,449.06c2.23,8.44,5.95,16.18,10.76,23.31,64.26-1.58,128.7-3.21,187.64-4.73-.57-3.18-1.65-7.97-3.65-13.6-61.16-1.61-128.13-3.34-194.75-4.98Z"
      />
      <path
        style={{ fill: color }}
        d="m1125.4 460.98s-109.34-3.1-250.81-6.83v13.48c141.22-3.65 250.81-6.64 250.81-6.64z"
      />
      <path
        style={{ fill: color }}
        d="M612.61,468.21c0-7.68,.73-14.51,2.03-20.63-28.9-.69-57.38-1.35-84.9-1.97-3.81,6.06-6.14,12.44-6.14,19,0-6.6-2.51-13.09-6.6-19.28-52.64-1.17-101.42-2.15-142.58-2.85,1.09,2.66,2.54,5.44,4.41,8.34,5.22,8.09,10.92,17.54,16.65,28.11,61.09-1.17,137.32-2.88,217.3-4.79-.1-1.95-.16-3.93-.16-5.94Z"
      />
      <path
        style={{ fill: color }}
        d="m1136.1 515.47s-204.99-5.76-417.79-11.02c10.08 7.66 20.78 14.56 30.87 21.03 200.66-4.96 386.92-10 386.92-10z"
      />
      <path
        style={{ fill: color }}
        d="M281.38,495.91c-157.83,0-213.71,8.76-213.71,19.56s81.93,19.56,213.71,19.56c15.23,0,38.35-.26,67.34-.72-6.09-13.96-12.75-26.61-19.74-38.05-18.89-.23-34.97-.36-47.6-.36Z"
      />
      <path
        style={{ fill: color }}
        d="m618.05 502.03c-77.48-1.83-151.8-3.45-213.14-4.57 5.29 11.05 10.36 22.97 14.86 35.57 61.41-1.22 135.36-2.88 212.12-4.7-5.79-7.92-10.51-16.63-13.84-26.3z"
      />
      <path
        style={{ fill: color }}
        d="m661.38 557.47c-84.07-2-166.48-3.83-235.36-5.13 3.26 11.26 5.95 22.93 7.85 34.9 76.78-1.53 170.72-3.68 264.9-5.93-12.94-7.25-25.75-15.01-37.39-23.84z"
      />
      <path
        style={{ fill: color }}
        d="M279.24,550.4c-160.2,0-216.91,8.76-216.91,19.56s83.16,19.56,216.91,19.56c18.68,0,49.06-.38,87.51-1.04-2.67-11.8-6.34-24.26-11.24-37.36-31.53-.47-57.62-.73-76.28-.73Z"
      />
      <path
        style={{ fill: color }}
        d="m1146.8 569.97s-155.02-4.29-336.35-8.85c10.32 5.2 20.93 10.64 31.07 16.7 167.24-4.17 305.28-7.85 305.28-7.85z"
      />
      <path
        style={{ fill: color }}
        d="m437.07 121.16c61.5 0 398.87-19.56 398.87-19.56s-325.21-19.56-398.87-19.56-99.74 8.76-99.74 19.56 38.24 19.56 99.74 19.56z"
      />
      <path
        style={{ fill: color }}
        d="m466.34 1088.5c0 10.8 12.81 19.56 48.99 19.56s195.95-19.56 195.95-19.56-165.73-19.56-195.95-19.56-48.99 8.76-48.99 19.56z"
      />
      <path
        style={{ fill: color }}
        d="m258.26 986.14c0 10.8 34.55 19.56 132.16 19.56s528.53-19.56 528.53-19.56-447.04-19.56-528.53-19.56-132.16 8.76-132.16 19.56z"
      />
      <path
        style={{ fill: color }}
        d="m205.91 934.95c0 10.8 38.92 19.56 148.87 19.56s595.37-19.56 595.37-19.56-503.57-19.56-595.37-19.56-148.87 8.76-148.87 19.56z"
      />
      <path
        style={{ fill: color }}
        d="m162.23 882.75c0 10.8 44.61 19.56 170.64 19.56s682.44-19.56 682.44-19.56-577.22-19.56-682.44-19.56-170.64 8.76-170.64 19.56z"
      />
      <path
        style={{ fill: color }}
        d="m130.18 830.55c0 10.8 47.35 19.56 181.11 19.56s724.33-19.56 724.33-19.56-612.65-19.56-724.33-19.56-181.11 8.76-181.11 19.56z"
      />
      <path
        style={{ fill: color }}
        d="m104.53 778.35c0 10.8 50.59 19.56 193.51 19.56s773.91-19.56 773.91-19.56-654.58-19.56-773.91-19.56-193.51 8.76-193.51 19.56z"
      />
      <path
        style={{ fill: color }}
        d="M666.28,716.27c11.53,9.37,26.16,17.51,44.7,22.9,36.06-.93,71.88-1.87,106.43-2.79,2.55-3.56,5.72-8.87,8.34-16.02-51.37-1.35-105.7-2.75-159.48-4.09Z"
      />
      <path
        style={{ fill: color }}
        d="M449.36,711.16c8.7,22.56,18.78,28,18.78,28h-126.48s9.05-10.15,17.37-29.77c-30.21-.51-54.23-.8-69.74-.8-127.1,0-206.12,8.76-206.12,19.56s53.89,19.56,206.12,19.56c57.99,0,191.43-2.84,334.41-6.35v-26.15c-61.96-1.52-121.92-2.93-174.34-4.05Z"
      />
      <path
        style={{ fill: color }}
        d="m1113.6 728.16s-102.26-2.87-235.59-6.41c-6.72 6.23-14.36 10.58-21.99 13.6 144.19-3.89 257.58-7.19 257.58-7.19z"
      />
      <path
        style={{ fill: color }}
        d="M643.07,690.67c63.04-1.53,127.29-3.16,187.49-4.73-.6-5.51-2.31-10.79-4.97-15.89-63-1.61-130.17-3.29-195.43-4.86,2.88,7.77,7.01,16.58,12.91,25.47Z"
      />
      <path
        style={{ fill: color }}
        d="M1125.38,677.96s-96.87-2.65-225.84-6c-.29,4.33-.83,8.39-1.58,12.21,129.95-3.45,227.41-6.21,227.41-6.21Z"
      />
      <path
        style={{ fill: color }}
        d="M279.24,658.39c-130.46,0-211.57,8.76-211.57,19.56s55.32,19.56,211.57,19.56c20.17,0,49.24-.33,84.57-.89,3.44-10.49,6.33-22.87,7.8-37.07-40.82-.73-73.03-1.16-92.37-1.16Z"
      />
      <path
        style={{ fill: color }}
        d="m623.7 665.05c-66-1.58-129.82-3.04-185.12-4.19 1.31 13.58 3.34 24.89 5.74 34.29 54.37-1.1 115.98-2.49 179.38-4.01v-26.09z"
      />
      <path
        style={{ fill: color }}
        d="m886.17 616.91c2.76 4.11 5.16 8.47 7.15 13.11 137.72-3.61 242.74-6.56 242.74-6.56s-108.91-2.95-249.89-6.55z"
      />
      <path
        style={{ fill: color }}
        d="M436.97,625.27c0,5.46,.1,10.64,.29,15.6,101.85-1.99,231.1-5.09,350.68-8.13-9.25-6.36-19.6-12.67-30.68-19.08-111.78-2.77-228.78-5.54-321.09-7.42,.52,6.29,.8,12.63,.8,19.02Z"
      />
      <path
        style={{ fill: color }}
        d="M369.91,604.98c-38.97-.68-69.75-1.08-88.53-1.08-131.78,0-213.71,8.76-213.71,19.56s55.87,19.56,213.71,19.56c21.47,0,52.93-.36,91.24-.99,.16-11.4-.63-23.76-2.71-37.06Z"
      />
      <path
        style={{ fill: color }}
        d="m337.33 1037.3c0 10.8 26.08 19.56 99.74 19.56s398.87-19.56 398.87-19.56-337.37-19.56-398.87-19.56-99.74 8.76-99.74 19.56z"
      />
      <path
        style={{
          fill: "#fff",
          stroke: color,
          strokeWidth: "4px",
          strokeMiterlimit: "10",
        }}
        d="M595.82,396.88h-.67c.21,0,.42,.01,.63,.02,.02,0,.04-.02,.04-.02Z"
      />
      <path
        style={{
          fill: "none",
          stroke: color,
          strokeWidth: "4px",
          strokeMiterlimit: "10",
        }}
        d="m803.6 396.88h71.33v74.93s-4.8-60.54-71.33-74.93z"
      />
      <path
        style={{
          fill: "none",
          stroke: color,
          strokeWidth: "4px",
          strokeMiterlimit: "10",
        }}
        d="m721.94 741.86h-97.87v-102.8s6.58 83.07 97.87 102.8z"
      />
      <path
        style={{
          fill: "none",
          stroke: color,
          strokeWidth: "4px",
          strokeMiterlimit: "10",
        }}
        d="m451.72 396.88h144.47s-72.23 31.17-72.23 67.74c0-35.37-72.23-67.74-72.23-67.74z"
      />
      <path
        style={{
          fill: "none",
          stroke: color,
          strokeWidth: "4px",
          strokeMiterlimit: "10",
        }}
        d="m400.17 396.88h-179.83s89.92 32.37 135.47 154.06c45.56 121.69-13.79 188.23-13.79 188.23h126.48s-31.17-16.78-31.17-113.89c0-71.4-34.17-137.27-58.15-174.44s20.98-53.95 20.98-53.95z"
      />
      <path
        style={{
          fill: "none",
          stroke: color,
          strokeWidth: "4px",
          strokeMiterlimit: "10",
        }}
        d="m680.71 396.88s-67.74-3-67.74 71.33 68.94 103.1 121.69 132.48c52.75 29.37 96.51 54.85 96.51 89.77s-17.98 51.4-17.98 51.4 89.93 8.64 86.92-82.42c-2.4-72.53-89.32-92.31-131.88-121.69-42.56-29.37-118.09-63.54-87.52-140.87z"
      />
    </svg>
  );
};
